"use client";

import { useParamsClient } from "@/hooks/params";
import routes from "@/lib/routes";
import { useTranslation } from "@/translation/i18n";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";

const HeaderMenu = () => {
    const { lang } = useParamsClient();
    const { translate } = useTranslation();
    const router = useRouter();

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpenMenu(false);
            }
        };

        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [menuRef]);

    const handleOpenMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const handleClick = (path: string, isExternal?: boolean) => {
        setIsOpenMenu(false);

        if (isExternal) {
            window.open(path, "_blank");
            return;
        }

        router.push(`/${lang}${path}`);
    };

    return (
        <nav
            className="flex items-center text-white font-extrabold relative"
            ref={menuRef}
        >
            <button
                type="button"
                className="text-[21.6px] z-[999] p-3 rounded-md lg:hidden"
                onClick={handleOpenMenu}
                aria-label="Icon menu"
            >
                <i className={`fas fa-${isOpenMenu ? "xmark" : "bars"}`} />
            </button>
            <div
                className={`${
                    isOpenMenu ? "top-0" : "-top-full"
                } w-full h-full z-[998] fixed right-0 flex flex-col justify-center items-center gap-1 border border-tertiary transition-all duration-500 ease-out backdrop-blur-lg backdrop-saturate-[180%] bg-[#00000040] lg:static lg:flex-row lg:gap-5 lg:bg-transparent lg:blur-none lg:border-none font-bold text-base`}
            >
                {routes.header.map(route => (
                    <button
                        type="button"
                        key={route.path}
                        className="cursor-pointer p-3 hover:text-[#ffffff90] group"
                        onClick={() => {
                            handleClick(route.path, route.external);
                        }}
                    >
                        <span className="cursor-pointer border-b-2  border-transparent group-hover:border-gray-400 transition-all duration-100">
                            {translate(route.name)}
                        </span>
                    </button>
                ))}
            </div>
        </nav>
    );
};

export default HeaderMenu;
